<template>
  <div class="drivers">
    <default-user-page v-bind="config"/>
  </div>
</template>

<script>
import {
  getDrivers,
  getDriver,
  postDriver, putDriver, removeDriver,
} from "@/services/drivers";
import DefaultUserPage from "@/components/default_user_page";

export default {
  components: {DefaultUserPage},
  data() {
    return {
      config: {
        label: 'service.driver',
        fetchRecords: getDrivers,
        fetchRecord: getDriver,
        postRecord: postDriver,
        putRecord: putDriver,
        removeRecord: removeDriver,
      }
    }
  }
}
</script>